'use strict';
jQuery(document).ready(function($){

     $('.home-slider').slick({
        infinite: true,
        autoplay: true,
        fade: true,
        arrows:false,
        dots:true,
        pauseOnHover: false
      });


    function isElementPartiallyInViewport(el)
    {
        // Special bonus for those using jQuery
        if (typeof jQuery !== 'undefined' && el instanceof jQuery) 
            el = el[0];
    
        var rect = el.getBoundingClientRect();
      
       // var windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        var windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    
       
       // var vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
        //var horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
        //var partiallyInView = ((windowWidth - rect.left) > 400 && (windowWidth - rect.left) < rect.width);
        //return (vertInView && horInView);
       
       return(windowWidth - rect.left);
    }
    function getScrollPercent() {
        var h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
    }  
//check if screen is large enough

$('.site-header').removeClass('dark'); 
if(screen.width > 768) {


    
    var sectionOne = document.getElementsByClassName("section-one")[0];
    var sectionTwo = document.getElementsByClassName("section-two")[0];
    var sectionThree = document.getElementsByClassName("section-three")[0];
    var sectionFour = document.getElementsByClassName("section-four")[0];
    var siteFooter = document.getElementsByClassName("site-footer")[0];
   
    var containerWidth = sectionTwo.clientWidth + sectionThree.clientWidth + sectionFour.clientWidth + siteFooter.clientWidth;

    var body = document.getElementsByTagName("BODY")[0];
    body.style.height = containerWidth+"px";
    containerWidth = "-"+containerWidth;
   
    var animation = anime({
        targets: '#container-content',
        translateX: containerWidth,
        elasticity: 200,
        easing:  'linear',
        autoplay: false
    });
    
    $('#aanbod-button').on('click', function(){
        $('html, body').animate({scrollTop:sectionOne.clientWidth - 250},1200);
    })
    $('.site-header').css('position','fixed');
    $(window).on('scroll', function(event){
       
        var percentage = getScrollPercent();
       
       animation.seek(animation.duration * (percentage / 100));
        
       if(isElementPartiallyInViewport(sectionTwo) > 400 && isElementPartiallyInViewport(siteFooter) < 400){
            $('.site-header').addClass('dark');
       } else if(isElementPartiallyInViewport(sectionTwo) > 400 && isElementPartiallyInViewport(siteFooter) > 400){
            $('.site-header').removeClass('dark');
       } else {
            $('.site-header').removeClass('dark');
       }
      
    });
  
} else {
    $('#aanbod-button').on('click', function(){
        $('html, body').animate({scrollTop:$('.home.section-two').offset().top - 30},500);
    })
}

});